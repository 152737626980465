const api = {
    products: '/products/',
    order: '/order/',
    count: '/count/',
};
const phone = '79030062189';
const links = {
    telegram: 'https://t.me/manager_irishome',
    whatsapp: 'https://wa.me/' + phone,
};
export {api, links};