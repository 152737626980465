import { useEffect, useState } from "react";
import { testCartProducts, testProducts } from "../utils";
import { ButtonMinus, ButtonPlus, CartItemRemoveButton } from "./buttons";
import { Form } from "./form";
import { Modal } from "./modal";
import { useCart } from "../cartContext";
import { fetchForm } from "../fetch";



const CartItem = ({cartItem}) => {
    const {id, images, name, size, price, quantity} = cartItem;
    const cart = useCart();

    const removeAllItem = () => {
      cart.removeFromCart(cartItem, true);
    }
    const decrementItem = () => {
      cart.removeFromCart(cartItem);
    }
    const incrementItem = () => {
      cart.addToCart({...cartItem, quantity: 1});
    }
    return (
      <div className="cart-item">
        <div className="cart-item__grid-col-1">
            <div className="cart-item__image">
                <img src={images[0]} />
            </div>
        </div>
        <div className="cart-item__grid-col-2">
            <div className="cart-item__name">{name}</div>
            <div className="cart-item__size">Размер: {size}</div>
            <div className="cart-item__quantity">
                <ButtonMinus onClick={decrementItem} />
                <span>{quantity}</span>
                <ButtonPlus onClick={incrementItem} />
            </div>
        </div>
        <div className="cart-item__grid-col-3">
            <div className="cart-item__remove">
                <CartItemRemoveButton onClick={removeAllItem} />
            </div>
            <div className="cart-item__price">{price} руб</div>
        </div>
      </div>
    );
}

const Cart = ({onClose}) =>
{
    const cart = useCart();
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [warning, setWarning] = useState(false);
    const [orderCreated, setOrderCreated] = useState(0); // 0, 1, 2
    const handler = (data) => {
      // if (!validateTotals()) return;

      setOrderCreated('Пожалуйста, ожидайте, Ваш запрос в обработке');
      
      fetchForm({...data, cart_items: cartItems})
      .then(r => {
            if (r.status === 200) {
              cart.clearCart();
              setOrderCreated(r.message);
            }
            });
    }
    // const validateTotals = () => {
    //   return (total / 1800) % 2 === 0;
    // }
    const updateCart = () => {

      setCartItems(cart.cart);
    }
    const calculateTotals = () => {
      let totals = 0;
      if (cartItems.length) {
        cartItems.forEach(item => {
          totals += item.quantity * item.price
        });
      }
      return totals;
    }
    useEffect(() => {
      updateCart();
    }, [cart.cart])
    useEffect(() => {
      setTotal(() => calculateTotals());
    },[cartItems]);

    return (
      <Modal classes="modal__cart" title="Корзина" onClose={onClose}>
        <div className="cart-wrapper">
          {/* {(!orderCreated) && <h2>Количество товаров в заказе должно быть кратно 2</h2>} */}
          {orderCreated ? <div className="order-created">{orderCreated}</div> : !(cartItems.length > 0) ? (
            <div className="cart-empty">Корзина пуста</div>
          ) : (
            <>
              <div className="cart-items">
                {cartItems.map((item, index) => {
                  return <CartItem cartItem={item} key={"cartItem-" + index} />;
                })}
              </div>
              <div className="cart-totals">{total} руб</div>
              <Form color="blue" formName="cart" onSubmit={handler} />
            </>
          )}


        
        </div>
      </Modal>
    );
};

export {Cart};