import { useState } from "react";
import { Input, TextAreaInput } from "./input";
import { validationName, validationPhone } from "../utils";
import { Button } from "./buttons";
import { formatPhoneNumber } from "../utils";

const handlePhoneChange = (value) => {
  const input = value;
  const phoneNumber = input.replace(/\D/g, "");
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  return formattedPhoneNumber;
};

const formatValue = (name, value) => {
  if (name !== "phone") return value;
  return handlePhoneChange(value);
};

const formNamesData = {
  cart: {
    name: "",
    phone: "",
    email: "",
    cart_items: "", // JSON.stringify
    // question: "",
  },
  question: {
    name: "",
    phone: "",
    email: "",
    // cart: "", // JSON.stringify
    question: "",
  }
}

const Form = ({ color, formName, onSubmit }) => {
  const [state, setState] = useState({
    formData: formNamesData[formName],
  });
  const dict = {
    name: "Имя",
    phone: "Телефон",
    email: "Электронная почта",
    question: "Ваш вопрос",
  };
  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setState((prevState) => ({
        formData: {
            ...prevState.formData,
            [name]: formatValue(name, value),
        },
    }));
  };

  const handler = (e) => {
    e.preventDefault();
    
    if (!validationName( state.formData.name )) return;
    if (!validationPhone( state.formData.phone )) return; 

    if (formName==="cart") {
      window.ym(95088224,'reachGoal','cartsend');
    } else {
      window.ym(95088224,'reachGoal','questionsend');
    }


    onSubmit(state.formData);
  }
  return (
    <form className={`form form-${formName}`}>
      <span className="form-description">Все поля обязательны для заполнения</span>
      {state &&
        Object.keys(state.formData).map((field, index) => {
          if (["name", "phone", "email", "question"].indexOf(field) === -1) return false;
          const key = `${formName}-${index}`;
          if (field === "question")
            return (
              <TextAreaInput
                name={field}
                label={dict[field]}
                color={color}
                value={state.formData[field]}
                onChange={onChange}
                key={key}
                id={key}
              />
            );
          return (
            <Input
              name={field}
              label={dict[field]}
              color={color}
              value={state.formData[field]}
              onChange={onChange}
              key={key}
              id={key}
            />
          );
        })}
      <Button color={color} onClick={handler}>Отправить</Button>
    </form>
  );
};

export { Form };
