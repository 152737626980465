import { useState } from "react";
import { Form } from "../components/form";
import QuestionItem from "../components/question_item";
import { H2, Subtitle } from "../components/text";
import { links } from "../constants";
import { fetchForm } from "../fetch";

const QuestionResponse = ({response}) => {
  return <div className="question-response">
    {response.message}
  </div>
} 

export default function Question() {
    const [questionResponse, setQuestionResponse] = useState(false);
    const certLink = () => {
      window.open('https://irishome.ru/aboutus/#gallery-1', '_blank');
    }
    const questionHandler = (data) => {
      setQuestionResponse({status: 'pending', message: 'Отправка...'});
      fetchForm(data)
        .then((r) => setQuestionResponse(r));
    }
    return (
      <section id="section-question" className="section-question">
        <div className="container question-headline">
          <H2>Остались вопросы</H2>
          <Subtitle>С удовольствием Вам ответим</Subtitle>
        </div>
        <div className="container question-content">
          <div className="question-left">
            <div className="chats">
              <a className="chats-link" href={links.telegram}>
                <div className="chats-link-icon telegram"></div>
                <span className="chats-link-text">Открыть чат Telegram</span>
              </a>
              <a className="chats-link" href={links.whatsapp}>
                <div className="chats-link-icon whatsapp"></div>
                <span className="chats-link-text">Открыть чат Whatsapp</span>
              </a>
            </div>
            <div className="question-form">
              {
              questionResponse 
                  ? <QuestionResponse response={questionResponse} /> 
                  : <Form color="brown" formName="question" onSubmit={questionHandler} />
              }
              </div>
          </div>
          <div className="question-right">
            <div className="questions-popular">
              <QuestionItem title="Доставка">
                <p>
                  Доставка товара не входит в стоимость и оплачивается отдельно.
                  Отправляем по всему миру. Почта РФ СДЭК
                </p>
              </QuestionItem>
              <QuestionItem title="Оплата">
                <p>
                Есть несколько способов оплаты заказа. Подробности уточняйте у менеджеров.
                </p>
              </QuestionItem>
              <QuestionItem title="Сертификат">
                <p>
                  Вся продукция имеет сертификаты соответствия. Вы можете
                  ознакомиться со свидетельством о прохождении процедуры
                  проверки качества в центре сертификации 
                  <a onClick={certLink} href="#cert"> по ссылке</a>.
                </p>
              </QuestionItem>
            </div>
          </div>
        </div>
      </section>
    );
}