import { useEffect, useState } from "react";
import { Button } from "../components/buttons";
import Product from "../components/product";
import { CountProducts, H2, Subtitle, Subtitle2 } from "../components/text";
import { fetchProducts } from "../fetch";
import { getQuestionForm } from "../utils";

const InProductNotification = () => <div className="product-notification">
    <span className="product-notification-title">Успейте купить</span>
    <CountProducts />
    <div className="product-notitfication-price">
        <span>Покрывало Софт</span>
        <span>2250 рублей</span>
    </div>
    <Button color="brown" classes="btn__product-notification" onClick={getQuestionForm}>Задать вопрос</Button>
</div>

const ProductList = ({products, openProduct}) => {
    return (
        <>
            {(products) && products.map((product,index) => {
                if (index > 3) return;
                const renderProduct = <Product key={product.id + index} product={product} openProduct={openProduct} />
                return renderProduct;  
            })}
            <InProductNotification />
            {(products) && products.map((product,index) => {
                    if (index < 3) return;
                    const renderProduct = <Product key={product.id + index} product={product} openProduct={openProduct} />
                    return renderProduct;  
            })}
        </>
    )
}

export default function Products({openProduct}) {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        fetchProducts()
            .then((data) => setProducts(data))
    },[]);
    return (
        <section id="section-product" className="section">
            <div className="products-headline">
                <div className="products-headline-inner">
                    <H2>Ликвидация остатков</H2>
                    <Subtitle2>*на покрывалах могут быть незначительные дефекты</Subtitle2>
                </div>
            </div>
            <div className="products-list">
                {(products.length) 
                    ? <ProductList products={products} openProduct={openProduct} /> 
                    : <div className="loader">Загрузка...</div>}
            </div>
        </section>
    )
}