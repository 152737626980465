import { useState } from "react";
import { ButtonMinus, ButtonPlus } from "./buttons";

export default function QuestionItem({title, children}) {
    const [open, setOpen] = useState(false);
    const onClick = (e) => {
        setOpen(!open);
    }
    return (
        <div className="question-item">
        {open && <div className="question-item-content">
          {children}
        </div>}
        <div 
            onClick={onClick}
            className="question-item-title">
          <div className="question-item-title-icon">
            {
            open 
              ? <ButtonMinus color="brown" />
              : <ButtonPlus color="brown" />
            }
          </div>
          <span className="question-item-title-text">{title}</span>
        </div>
      </div>
    )
}