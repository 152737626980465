export default function AboutBlock({children, image, reverse=false}) {
    return (
        <div className={`about-block about-block__${reverse ? 'reverse' : 'normal'}`}>
            <div className="about-block-inner about-block__text">
                <div className="about-block__text-wrapper">
                    {children}
                </div>
            </div>
            <div className="about-block-inner about-block-image-wrapper">
                {image}
            </div>
        </div>
    );
}