import { api } from "./constants";

const fetchCount = async () => {
    const req = await fetch(api.count);
    const res = await req.json();
    return res;
}

const fetchForm = async (data) => {
    const req = await fetch(api.order, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    const res = await req.json();

    return res;
}
const fetchProducts = async () => {
    const req = await fetch(api.products);
    const res = await req.json();

    return res;
}
export {fetchForm, fetchProducts, fetchCount};