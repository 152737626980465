import { Button } from "../components/buttons";
import { CountProducts, H1, Span } from "../components/text";
import { getQuestionForm } from "../utils";

export default function Header() {
    return (
        <section className="section" id="section-header">
            <div className="left-side">
                <div className="headline">
                    <H1>Ликвидация<br /> покрывал софт</H1>
                    <div className="headline-list">
                        <Span>Комплект покрывало софт</Span>
                        <Span>за <b>2250</b> рублей</Span>
                        <Span>Цена без скидки <b>5000</b> рублей</Span>
                    </div>
                    <div className="headline-description">
                        <Span><b>*На продукции имеются незначительные дефекты</b></Span>
                        <Span>Затяжка, дефект ткани или немного испачкано</Span>
                    </div>
                    <CountProducts />
                    <Button color="brown" onClick={getQuestionForm}>Задать вопрос</Button>
                </div>
            </div>
            <div className="right-side"></div>
        </section>
    )
}