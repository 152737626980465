import { useEffect, useState } from "react";
import { Button, ButtonMinus, ButtonPlus, ButtonSize } from "./buttons";
import { Modal } from "./modal";
import { useCart } from "../cartContext";
import { CartItem } from "../types";

const ProductDetail = ({product, openProduct}) => {
    const cart = useCart();
    const {id,price,name,images,sku,sizes} = product;
    const [quantity, setQuantity] = useState(1);
    const addToCart = () => {
        cart.addToCart(new CartItem(
            id,sku,name,price,images,quantity,size
        ));
        setAdding(true);

        setTimeout(() => {
            setAdding(false);
        }, 1000);
    }
    const [size, setSizes] = useState(false);
    useEffect(() => {
        setSizes(sizes[0])
    }, [sizes]);
    const [activeImage, setActiveImage] = useState(0);
    useEffect(() => {
        document.querySelector('.product-detail-gallery-wrapper')
            .animate({
                transform: `translateX(-${activeImage}00%)`,
            }, {duration: 300, iterations: 1, fill: 'both'});
        
    }, [activeImage]);
    const decrement = () => {
        if (quantity < 2) return;
        setQuantity(quantity-1);
    }
    const increment = () => {
        setQuantity(quantity+1);
    }
    const [adding, setAdding] = useState(false);

    return (
      <Modal onClose={openProduct} classes="product-modal">
        <div className="product-detail">
          <div className="product-detail-gallery">
            <div className="product-detail-gallery-wrapper">
              {images.map((image, index) => {
                return (
                  <div
                    key={index + "gallery-image"}
                    data-slide={index}
                    className="product-detail-gallery-item"
                  >
                    <img src={image} alt={name} title={name} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="product-detail-gallery-thumbnails">
            {images.map((image, index) => {
              return (
                <div
                  onClick={() => setActiveImage(index)}
                  key={index + "gallery-thumb"}
                  data-slide={index}
                  className={`product-detail-gallery-thumb ${
                    activeImage == index && "active"
                  }`}
                >
                  <img src={image} alt={name} title={name} />
                </div>
              );
            })}
          </div>
          <div className="product-detail-info">
            <div className="product-detail-info-sku">{sku}</div>
            <div className="product-detail-info-name">{name}</div>
            <div className="product-detail-info-sizes">
              <div className="product-detail-info-sizes-title">
                <span className="title">Размер</span>
                <span className="caption">в сантиметрах</span>
              </div>
              <div className="product-detail-info-sizes-list">
                {sizes.map((item) => {
                  return (
                    <ButtonSize
                      classes={item == size ? "active" : ""}
                      key={item}
                      onClick={() => setSizes(item)}
                    >
                      {item}
                    </ButtonSize>
                  );
                })}
              </div>
            </div>
            <div className="product-detail-info-quantity">
              <ButtonMinus onClick={decrement} />
              <span>{quantity}</span>
              <ButtonPlus onClick={increment} />
            </div>
            <div className="product-detail-info__add-to-cart">
              <Button onClick={addToCart} color="blue" classes="btn-addtocart">
                <svg
                  width="25"
                  height="23"
                  viewBox="0 0 25 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9219 13.0781L24.9737 4.0503C25.1218 3.39848 24.6263 2.77778 23.9579 2.77778H6.91007L6.51224 0.832899C6.41311 0.348134 5.98655 0 5.49171 0H1.04167C0.466363 0 0 0.466363 0 1.04167V1.73611C0 2.31141 0.466363 2.77778 1.04167 2.77778H4.07478L7.12374 17.6838C6.39431 18.1033 5.90278 18.8898 5.90278 19.7917C5.90278 21.134 6.99097 22.2222 8.33333 22.2222C9.67569 22.2222 10.7639 21.134 10.7639 19.7917C10.7639 19.1114 10.4841 18.4967 10.0337 18.0556H19.1329C18.6826 18.4967 18.4028 19.1114 18.4028 19.7917C18.4028 21.134 19.491 22.2222 20.8333 22.2222C22.1757 22.2222 23.2639 21.134 23.2639 19.7917C23.2639 18.8293 22.7045 17.9977 21.8933 17.6039L22.1327 16.5503C22.2809 15.8985 21.7854 15.2778 21.117 15.2778H9.46688L9.18281 13.8889H21.9061C22.3925 13.8889 22.8141 13.5523 22.9219 13.0781Z"
                    fill="white"
                  />
                </svg>
                {!adding ? 
                    <span className="add">В корзину</span> 
                    : 
                    <span className="added">Добавлен</span>}
                
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
}


export {ProductDetail}