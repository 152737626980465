
class Product
{
    constructor(id,name,price,oldPrice,images,available)
    {
        this.id = id;
        this.name = name;
        this.price = price;
        this.oldPrice = oldPrice;
        this.images = images;
        this.available = available;
    }
    getPreviewImage() {
        return this.images[0];
    }
    getSalePercent() {
        return this.price / this.oldPrice * 100;
    }
}

class CartItem
{
    constructor(id,sku,name,price,images,quantity,size)
    {
        this.id = id;
        this.sku = sku;
        this.name = name;
        this.price = price;
        this.quantity = quantity;
        this.size = size;
        this.images = images;
    }
    
    getTotalPrice() {
        return this.quantity * this.price;
    }
    getAvailable() {
        return this.available - this.quantity;
    }
    
}

class Cart
{
    // Добавляется объет Product в корзину
    constructor () {
        this.items = [];
        this.subscribers = [];
    }
    subscribe(func) {this.subscribers.push(func)}
    update() {this.subscribers.forEach(func => func())}
    addItem(product) {
        const itemLast = this.items.filter(item => product.id == item.id);
        if (itemLast) 
        {
            itemLast.quantity++;
        } else {
            this.items.push(new CartItem(
                {...product}
            ))
        }
    }
}

export {CartItem, Product, Cart};