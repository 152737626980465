import { useEffect, useState } from 'react';
import { CartButton } from './components/buttons';
import About from './sections/about';
import Deffect from './sections/deffect';
import Header from './sections/header';
import Products from './sections/products';
import Question from './sections/questions';
import { Cart } from './components/cart';
import { ProductDetail } from './components/product_detail';
import { CartProvider } from './cartContext';



export default function App() {
    const [cartOpen, setCartOpen] = useState(false);
    const [productDetail, setProductDetail] = useState(false);
    const openCart = (e) => {
        setCartOpen(!cartOpen);
    }
    const openProduct = (product) => {
        if (productDetail === false) {
            setProductDetail(product);
        } else {
            setProductDetail(false);
        }
    }
    
    return (
        <CartProvider>
            {cartOpen && <Cart onClose={openCart} />}
            {productDetail && <ProductDetail product={productDetail} openProduct={openProduct} />}
            <CartButton onClick={openCart} />
            <Header />
            <About />
            <Products openProduct={openProduct}  />
            <Deffect />
            <Question />
        </CartProvider>
    )
}