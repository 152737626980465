function generateRandomId(length) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomId = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomId += charset[randomIndex];
  }

  return randomId;
}


const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber === "") return phoneNumber;
  const cleaned = phoneNumber.slice(0, 11); // Ограничиваем длину номера до максимально допустимой
  // const match = cleaned.match(/^(\+\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  const digits = cleaned.split("");
  // Формируем номер в соответствии с маской
  let formattedPhoneNumber = "+";

  switch (digits.length) {
    case 0:
      formattedPhoneNumber += "";
      break;
    case 1:
      formattedPhoneNumber += `${digits[0]}`;
      break;
    case 2:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}`;
      break;
    case 3:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}`;
      break;
    case 4:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]}`;
      break;
    case 5:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}`;
      break;
    case 6:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}`;
      break;
    case 7:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}`;
      break;
    case 8:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}-${digits[7]}`;
      break;
    case 9:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}`;
      break;
    case 10:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}-${digits[9]}`;
      break;
    case 11:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}-${digits[9]}${digits[10]}`;
      break;
    default:
      formattedPhoneNumber += `${digits[0]}-(${digits[1]}${digits[2]}${digits[3]})-${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}-${digits[9]}${digits[10]}`;
      break;
  }

  return formattedPhoneNumber;
};


function validationPhone(field) {
    const phone = field.replace(/[^\d]/g, '');
    if (phone.length !== 11) return false;
    return true;
}

function validationName(field) {
    if (field.length < 3) return false;
    return true;
}

function getQuestionForm() {
  // const sectionQuestionOffsetTop = document.getElementById('section-question').offsetTop;
  // window.scrollTo({top: sectionQuestionOffsetTop, behavior: 'smooth'});
  // setTimeout(() => {
    window.location.href = '#section-question';
  // }, 2000);
}

function animationSection() {

  const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const sectionLeft = entry.target.firstChild;
        const sectionRight = entry.target.lastChild;
        
        if (entry.isIntersecting) {
          sectionLeft.classList.add('square-animation-left');
          sectionRight.classList.add('square-animation-right');
          return; // если класс добавлен, продолжать уже не надо
        }
        // перемещение завершено, теперь надо удалить класс
        sectionLeft.classList.remove('square-animation-left');
        sectionRight.classList.remove('square-animation-right');
      });
    });
    
    document.querySelectorAll('section').forEach(el => observer.observe(el));
  
}

export { getQuestionForm, generateRandomId, formatPhoneNumber, validationPhone, validationName, animationSection };
