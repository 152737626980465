import { createContext, useContext, useEffect, useState } from 'react';

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      return savedCart;
    }
    return [];
  });
  const clearCart = () => {
    setCart([]);
  }
  const addToCart = (item) => {
    const index = cart.findIndex((cartItem) => {
        if (cartItem.id === item.id && cartItem.size === item.size) {
            return cartItem;
        }
    });
    if (index !== -1) {
        cart[index].quantity += item.quantity;
    } else {
        cart.push(item);
    }
    setCart([...cart]);
  };

  const removeFromCart = (item, all=false) => {
    const index = cart.findIndex((cartItem) => {
        if (cartItem.id === item.id && cartItem.size === item.size) {
            return cartItem;
        }
    });

    if (index !== -1) {
        if (all) {
            cart.splice(index,1);
        } else if (cart[index].quantity > 1) {
            cart[index].quantity--;
        }
    } 
    setCart([...cart]);
  };

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}