const onFocus = (e) => {
    e.target.closest('label').querySelector('span').classList.add('active');
}
const onBlur = (e) => {
    if (e.target.value == '') e.target.closest('label').querySelector('span').classList.remove('active');
}
const TextAreaInput = ({color, label, name, value, id, onChange}) => {
    const labelText = `label-text-${id}`;

    return <label className="label label__textarea" htmlFor={id}>
        <span id={labelText} className={`input-label input-textarea-label input-label__${color}`}>{label}</span>
        <textarea
            onBlur={onBlur}
            onFocus={onFocus} 
            onChange={onChange} 
            type="text" 
            className={`input input-textarea input-${color}`} 
            id={id} 
            name={name} 
            value={value} 
        />
    </label>
}
const Input = ({color, label, name, value, id, onChange}) => {
    const labelText = `label-text-${id}`;

    return <label className="label" htmlFor={id}>
        <span id={labelText} className={`input-label input-label__${color}`}>{label}</span>
        <input
            onBlur={onBlur}
            onFocus={onFocus} 
            onChange={onChange} 
            type={['phone', 'email'].indexOf(name) !== -1 ? name : 'text'} 
            className={`input input-${color}`} 
            id={id} 
            name={name} 
            value={value} 
        />
    </label>
}
export {Input, TextAreaInput}