import { Button } from "./buttons";

export default function Product({product, openProduct}) {
    const {images, name, price, oldPrice, available} = product;
    const handler = () => {
      openProduct(product);
    }
    return (
      <div className="product">
        <img
          src={images[0]}
          className="product-image"
          title={name}
          alt={name}
        />
        <div className="product-info">
          <Button color="blue" classes="btn__add-to-cart" onClick={handler}>
            <div className="icon icon-cart"></div>
            <span>В корзину</span>
          </Button>
          <div className="product-info-right">
            <div className="product-info-price">
              <span className="old-price">{oldPrice} ₽</span>
              <span className="price">{price} ₽</span>
            </div>
            <div className="product-info-available">
              <span>Наличие</span>
              <span>{available} шт</span>
            </div>
          </div>
        </div>
      </div>
    );
}