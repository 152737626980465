import { H3 } from "../components/text";

export default function Deffect() {
    return (
        <section id="section-deffects" className="section-deffects">
            <div className="container deffect-headline">
                <H3>Дефекты</H3>
                <p>Все покрывала имеют незначительный брак - затяжка, дефект ткани или немного испачкано. <br/>Товар произведен в РФ, на фабрике.</p>
            </div>
            <div className="deffect-gallery">
                <div className="deffect-gallery-wrapper">
                    <div className="deffect-gallery-item">
                        <img src="/img/deffects/deffect_1.jpg" alt="Деффекты на покрывалах" title="Деффекты на покрывалах" />
                    </div>
                    <div className="deffect-gallery-item">
                        <img src="/img/deffects/deffect_2.jpg" alt="Деффекты на покрывалах" title="Деффекты на покрывалах" />
                    </div>
                    <div className="deffect-gallery-item">
                        <img src="/img/deffects/deffect_3.jpg" alt="Деффекты на покрывалах" title="Деффекты на покрывалах" />
                    </div>
                </div>
            </div>
        </section>
    )
}