import { useEffect } from "react";
import AboutBlock from "../components/about_block";
import {H3} from "../components/text";
import { animationSection } from "../utils";

const MaterialImage = <div className="about-block-image material-image"></div>;
const ColorImage = <div className="about-block-image color-image"></div>
const SizesImage = <div className="about-block-image sizes-image"></div>

export default function About() {
    useEffect(() => {
        animationSection();
    },[]);
    return (
        <section id="section-about" className="section">
            <AboutBlock image={MaterialImage} reverse={true}>
                <H3>Материал</H3>
                <p>Ткань Софт - это исключительный выбор для вашего дома. Она находит широкое применение среди дизайнеров, помогая создать стильные интерьерные решения.</p>
                <br />
                <span>Характеристики:</span>
                <ul>
                    <li><b>Плотность</b></li>
                    <li><b>Мягкость</b></li>
                    <li><b>Прочность</b></li>
                </ul>
            </AboutBlock>
            <AboutBlock image={ColorImage} reverse={false}>
                <H3>Цвет</H3>
                <p>У нас в наличии 15 разнообразных вариантов цвета, чтобы удовлетворить любой вкус и подчеркнуть уникальность вашего интерьера. Ткань 'Софт' - это не только мягкость и качество, но и множество стильных цветовых решений для вашего комфорта.</p>
            </AboutBlock>
            <AboutBlock image={SizesImage} reverse={true}>
                <H3>Размер</H3>
                <p>У нас есть покрывала различных размеров, чтобы удовлетворить ваши предпочтения:</p>
                <ul>
                    <li><b>120/200 см</b> - полуторное покрывало</li>
                    <li><b>220/240 см</b> - евро-размер</li>
                    <li><b>240/260 см</b> - евро-макси</li>
                </ul>
                <p>Выберите подходящий размер для вашей постели и наслаждайтесь комфортом в стиле.</p>
            </AboutBlock>
        </section>
    )
}