import { ModalCloseButton } from "./buttons";

const Modal = ({children, onClose, title = "", classes=""}) => {
    return (
        <div className={`modal${classes!==""?' ' + classes : ''}`}>
            <div className="modal-wrapper">
                <div className="modal-inner">
                    <span className="modal-title">
                        {title}  
                    </span>
                    <ModalCloseButton onClick={onClose} />
                    {children}
                </div>
            </div>
        </div>
    )
}

export {Modal};