import { useState } from "react";

const Button = ({ children, color, classes = "", onClick }) => {
  return <button onClick={onClick} className={`btn btn-${color} ${classes}`}>{children}</button>;
};
const CartButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className="btn-cart-wrapper">
      <img className="btn-cart" src="img/cart.svg" />
    </div>
  );
};

const ModalCloseButton = ({ onClick }) => {
  return (
    <div className="btn-modal-close" onClick={onClick}>
      <img src="img/btn-modal-close.svg" />
    </div>
  );
};
const CartItemRemoveButton = ({ onClick }) => {
  return (
    <div className="btn-cartitem-remove" onClick={onClick}>
      <img src="img/btn-modal-close.svg" />
    </div>
  );
};

const colors = {
  blue: '#2B3153',
  brown: '#816254'
}
const ButtonPlus = ({ color="blue", onClick }) => {
  return (
    <div onClick={onClick} className="btn-plus">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill={colors[color]} />
        <path d="M3.617 9.59197H16.383V10.8686H3.617V9.59197Z" fill="white" />
        <path
          d="M9.14892 16.826V4.06006H10.4255V16.826H9.14892Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
const ButtonMinus = ({ color="blue", onClick }) => {
  return (
    <div onClick={onClick} className="btn-minus">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill={colors[color]} />
        <path d="M3.617 9.59197H16.383V10.8686H3.617V9.59197Z" fill="white" />
      </svg>
    </div>
  );
};

const ButtonSize = ({children,onClick, classes}) => {
  return <div onClick={onClick} className={`btn btn-size${' '+classes}`}>{children}</div>
}

export {
  Button,
  CartButton,
  ModalCloseButton,
  CartItemRemoveButton,
  ButtonMinus,
  ButtonPlus,
  ButtonSize,
};
