import { useEffect, useRef, useState } from "react";
import { fetchCount } from "../fetch";

const H1 = ({ children }) => {
  return <h1 className="H1">{children}</h1>;
};
const H2 = ({ children }) => {
  return <h2 className="H2">{children}</h2>;
};
const H3 = ({ children }) => {
  return <h3 className="H3">{children}</h3>;
};
const Subtitle = ({ children }) => {
  return <span className="subtitle">{children}</span>;
};
const Subtitle2 = ({ children }) => {
  return <span className="subtitle-2">{children}</span>;
};
const Span = ({ children }) => {
  return <span>{children}</span>;
};
const CountProducts = () => {
  const [count, setCount] = useState(0);
  const refCount = useRef();
  const animationCount = () => {
        if (count) {
            let i = 0; 

            setInterval(() => {
                if (i <= count) {
                    refCount.current.innerText = i;
                    i++;
                }
            }, 1);
        }
  }
  useEffect(() => {
    fetchCount().then((r) => {
        setCount(r);
    });
  }, []);
  useEffect(() => {
    if (count) {
        animationCount();
    }
  }, [count])

  return (
    <div className="headline-count-products">
      <div className="headline-count-products__caption">
        <span>Осталось шт.</span>
      </div>
      <div className="headline-count-products__count">
        <span ref={refCount}>{count}</span>
      </div>
    </div>
  );
};

export { H1, H2, H3, Subtitle, Subtitle2, Span, CountProducts };
